import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ERROR_MESSAGES } from "../GlobalErrorMessage";
import { NewsletterAPI } from "../../ApiServices/NewsLettter/NewsLetterApi";
import SuccessModal from "../SuccessModal";

const Footer = () => {
  const [error, setError] = useState(null);
  const [successPopUp, setSuccessPopUp] = useState(null);
  const userID = localStorage.getItem("userID") || "";
  const [newsLetterObj, setNewsLetterObj] = useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  const handleSubscribe = () => {
    debugger;
    let isValid = false;
    if (
      newsLetterObj.firstName === "" ||
      newsLetterObj.firstName === null ||
      newsLetterObj.firstName === undefined ||
      newsLetterObj.lastName === "" ||
      newsLetterObj.lastName === null ||
      newsLetterObj.lastName === undefined ||
      newsLetterObj.email === "" ||
      newsLetterObj.email === null ||
      newsLetterObj.email === undefined
    ) {
      setError(true);
      isValid = true;
    }

    const api_params = {
      FName: newsLetterObj.firstName,
      LName: newsLetterObj.lastName,
      Email: newsLetterObj.email,
      UserID: userID,
    };

    if (!isValid) {
      addUpdateNewsLetter(api_params);
    }
  };

  const addUpdateNewsLetter = async (api_params) => {
    debugger;
    try {
      const res = await NewsletterAPI(api_params);

      if (res.ResponseMessage === "Success") {
        setSuccessPopUp(true);
        console.log("done newsletterAdded");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setInitialData = () => {
    setError(false);
    setNewsLetterObj((prev) => ({
      ...prev,
      firstName: null,
      lastName: null,
      email: null,
    }));
  };
  return (
    <footer className="" style={{ backgroundColor: "#A52444" }}>
      <div
        className=""
        style={{
          backgroundImage: `url('assets/images/backgrounds/footer-bg-1-1.jpg')`,
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: "-1",
        }}
      ></div>

      <div className="" style={{ margin: "20px" }}>
        <div className="row">
          <div
            className="col-md-6 col-xl-3 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div className="footer-widget footer-widget--about footert">
              <a href="index.html" className="footer-widget__logo">
                <img
                  src="assets/images/new/logo (2).png"
                  width="223"
                  alt="myom namo"
                />
              </a>
              <p className="footer-widget__text">
                Myomnamo is an earnest venture to solve the problem by
                synergizing your spiritual and professional world. We assure to
                bring you closer to the veteran pandit who would guide you in
                the rituals.
              </p>
              <div
                className="footer-widget__social"
                style={{ marginLeft: "18px" }}
              >
                <a
                  href="https://www.facebook.com/omnamoapp/"
                  style={{ "--accent-color": "#ffa415" }}
                >
                  <i className="fab fa-facebook-f" aria-hidden="true"></i>
                  <span className="sr-only">Facebook</span>
                </a>
                <a
                  href="https://www.linkedin.com/company/my-om-namo/"
                  style={{ "--accent-color": "#44c895" }}
                >
                  <i className="fa-brands fa-linkedin" aria-hidden="true"></i>
                  {/* <i className="fab fa-instagram" aria-hidden="true"></i> */}
                  <span className="sr-only">Instagram</span>
                </a>
                <a
                  href="https://x.com/My_OmNamo"
                  style={{ "--accent-color": "#44c895" }}
                >
                  {/* <i className="fab fa-instagram" aria-hidden="true"></i> */}
                  <i className="fa-brands fa-x-twitter" aria-hidden="true"></i>
                  {/* <i class="fab fa-twitter" aria-hidden="true"></i> */}
                  <span className="sr-only">X</span>
                </a>
                <a
                  href="https://www.youtube.com/@myomnamo-beliefredefined9691"
                  style={{ "--accent-color": "#44c895" }}
                >
                  {/* <i className="fab fa-instagram" aria-hidden="true"></i> */}
                  <i className="fa-brands fa-youtube" aria-hidden="true"></i>
                  {/* <i class="fab fa-twitter" aria-hidden="true"></i> */}
                  <span className="sr-only">Youtube</span>
                </a>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-xl-2 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div className="footer-widget footer-widget--links-two footert">
              <h2 className="footer-widget__title">Quick Link</h2>
              <ul
                className="list-unstyled footer-widget__links"
                style={{ marginLeft: "3px" }}
              >
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/spiritual-gyan">Spiritual Gyan</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/Terms-And-Condition">Terms And Conditions</a>
                </li>
                <li>
                  <a href="/Privacy-policy">Privacy Policies</a>
                </li>
                <li>
                  <a href="/faq">FAQs</a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="col-md-6 col-xl-2 wow fadeInUp"
            data-wow-delay="150ms"
          >
            <div className="footer-widget footer-widget--links footert">
              <h2 className="footer-widget__title">Services</h2>
              <ul
                className="list-unstyled footer-widget__links"
                style={{ marginLeft: "3px" }}
              >
                <li>
                  <Link
                    to="/services/puja-booking"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Puja Booking
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/daily-booking"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Daily Puja Booking
                  </Link>
                </li>
                <li>
                  <Link to="/my-store" onClick={() => window.scrollTo(0, 0)}>
                    E-Store
                  </Link>
                </li>

                <li>
                  <Link
                    to="/services/darshan-booking"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Darshan Booking
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/temple-puja"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Puja At Temple
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/horoscope"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Horoscope
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="col-md-6 col-xl-2 wow fadeInUp"
            data-wow-delay="250ms"
          >
            <div className="footer-widget footer-widget--contact footert">
              <h2 className="footer-widget__title">Contact Info</h2>
              <ul
                className="list-unstyled footer-widget__info"
                style={{ marginLeft: "0" }}
              >
                <li style={{ "--accent-color": "#ffa415" }}>
                  <span className="footer-widget__info__icon">
                    <i
                      className="icon-phone-call"
                      style={{ color: "white" }}
                    ></i>
                  </span>
                  <a href="tel:+918879222277">+91 8879222277</a>
                </li>
                <li style={{ "--accent-color": "#8139e7" }}>
                  <span className="footer-widget__info__icon">
                    <i className="icofont-email" style={{ color: "white" }}></i>
                  </span>
                  <a href="mailto:info@myomnamo.com">info@myomnamo.com</a>
                </li>

                <li style={{ "--accent-color": "#ff5528" }}>
                  <span className="footer-widget__info__icon">
                    <i
                      className="icofont-location-pin"
                      style={{ color: "white" }}
                    ></i>
                  </span>
                  My Om Namo Spiritual Services Pvt Ltd A 321, Lodha Supremus
                  II, Road No 22, Wagle Estate, Thane West, 400 604
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-md-6 col-xl-3 wow fadeInUp"
            data-wow-delay="250ms"
          >
            <div className="footer-widget footer-widget--contact footert">
              {/* Newsletter Section */}
              <div
                className="single-nav-area-footer news-letter"
                style={{ marginTop: "20px" }}
              >
                <h4
                  className="title"
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Newsletter
                </h4>
                <p style={{ color: "#f1f1f1", fontSize: "14px" }}>
                  Stay updated with our latest updates.
                </p>
                <form
                  // action="#"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  <input
                    type="text"
                    placeholder="First Name"
                    value={newsLetterObj.firstName}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (inputVal.startsWith(" ")) {
                        inputVal = inputVal.trimStart();
                      }

                      setNewsLetterObj((prev) => ({
                        ...prev,
                        firstName: inputVal,
                      }));
                    }}
                    required
                    style={{
                      flex: "1",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "none",
                      fontSize: "14px",
                      outline: "none",
                    }}
                  />
                  {error &&
                  (newsLetterObj.firstName === "" ||
                    newsLetterObj.firstName === undefined ||
                    newsLetterObj.firstName === null) ? (
                    <span className="error-msg">{ERROR_MESSAGES}</span>
                  ) : (
                    ""
                  )}
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={newsLetterObj.lastName}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (inputVal.startsWith(" ")) {
                        inputVal = inputVal.trimStart();
                      }

                      setNewsLetterObj((prev) => ({
                        ...prev,
                        lastName: inputVal,
                      }));
                    }}
                    required
                    style={{
                      flex: "1",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "none",
                      fontSize: "14px",
                      outline: "none",
                    }}
                  />
                  {error &&
                  (newsLetterObj.lastName === "" ||
                    newsLetterObj.lastName === undefined ||
                    newsLetterObj.lastName === null) ? (
                    <span className="error-msg">{ERROR_MESSAGES}</span>
                  ) : (
                    ""
                  )}
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={newsLetterObj.email}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (inputVal.startsWith(" ")) {
                        inputVal = inputVal.trimStart();
                      }

                      setNewsLetterObj((prev) => ({
                        ...prev,
                        email: inputVal,
                      }));
                    }}
                    required
                    style={{
                      flex: "1",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "none",
                      fontSize: "14px",
                      outline: "none",
                    }}
                  />
                  {error &&
                  (newsLetterObj.email === "" ||
                    newsLetterObj.email === undefined ||
                    newsLetterObj.email === null) ? (
                    <span className="error-msg">{ERROR_MESSAGES}</span>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={handleSubscribe}
                    className="btn-subscribe"
                    style={{
                      backgroundColor: "#ff9900",
                      color: "#000000",
                      border: "none",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      // width:'20vw',
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-footer__bottom wow fadeInUp" data-wow-delay="300ms">
        <div>
          <div className="main-footer__bottom__inner">
            <p className="main-footer__copyright">
              &copy; Copyright <span className="dynamic-year"></span> By
              Myomnamo Spiritual Services Private Limited
            </p>
          </div>
        </div>
      </div>
      <SuccessModal
        show={successPopUp}
        onHide={() => setSuccessPopUp(false)}
        onConfirm={() => {
          setInitialData();
          setSuccessPopUp(false);
          window.location.reload(); // This will refresh the page
        }}
      />
    </footer>
  );
};

export default Footer;
